<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <h5>Created by user wise Bills </h5>
      </b-card-title>
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>
      <div v-else-if="!loading && chartData.records.length === 0">
        <p>No infromation available.</p>
      </div>
      <vue-apex-charts
        v-else
        type="bar"
        height="350"
        :series="series"
        :options="chartOptions"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import formatMixin from '@/mixins/formatMixin'
import {
  BCard, BCardBody, BCardTitle, BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard, BCardBody, BCardTitle, VueApexCharts, BSpinner,
  },
  mixins: [formatMixin],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      series: [{
        name: 'No. of Bills',
        data: this.$props.chartData.records.map(item => item.bill_count),
      }],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        colors: ['#826af9', '#d2b0ff'],
        dataLabels: {
          enabled: true,
          offsetY: -40,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
          formatter: (val, opts) => {
            const index = opts.dataPointIndex
            return [val, `(${this.formateNumber(this.$props.chartData.records[index].total_amount)})`]
          },
        },
        xaxis: {
          categories: this.$props.chartData.records.map(data => data.generated_by),
          title: {
            text: 'Created By',
          },
        },
        yaxis: {
          title: {
            text: 'No. of Bills',
          },
          stepSize: 1,
        },
        tooltip: {
          y: {
            formatter: (val, opts) => {
              const index = opts.dataPointIndex
              return `${val} (${this.formateNumber(this.$props.chartData.records[index].total_amount)})`
            },
          },
        },
      },
    }
  },
  watch: {
    chartData: {
      handler(newData = {}) {
        this.series[0].data = newData.records.map(data => data.bill_count)
        this.chartOptions.xaxis.categories = newData.records.map(data => data.generated_by)
      },
      deep: true,
    },
  },
}
</script>
